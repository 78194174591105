// LazyLoader
// ===================
// Load content images as desired by the end user.
// TODO support horizontal scrolling and non-window scrolling containers.
define([
  'underscore',
  'common/widgets/bc.ajaxloader',
  'common/widgets/swapresponsiveimage/bc.swapresponsiveimage',
  'vendors/jquery.preload',
  'jquery-ui/widget',
],
function({ bind }) {
  $.widget('bc.lazyloader', {
    options: {

      // Optionally show ajax loaders. Accepts boolean (`true`) to use the widget defaults
      // or an options object that will be fed to the ajax loader instance.
      ajaxloader: false,

      // If `true` will bind to window scroll events and automatically load images
      // when they fall within the proximity threshold.
      auto: false,

      // Automatically enqueue matching assets.
      enqueue: true,

      // How far away images should be from the viewport to load them.
      // Low proximity (including negative numbers) is more efficient at the cost of performance (images appear loading in the viewport).
      // Set to `false` to disable the proximity check.
      proximity: false,

      // Selector of those images that swap depending on the current breakpoint.
      responsiveImages: '.js-responsive-image',

      // Optionally limit the number of images that can be loaded in parallel by setting this to an integer > 0.
      // A value of `false` or 0 simply loads all images that meet the proximity threshold.
      // If the user will be scrolling a lot, throttling will help overall performance
      // by allowing images closer to the viewport to load quicker.
      throttle: false,

      // Context
      currentContext: window,

      // @2xsuffix
      highResSuffix: '_2x',
    },

    _create() {
      var self = this;
      var options = self.options;
      var $el = self.element;

      self._$images = $();
      self._$queue = $();
      self._loading = 0;
      self._timeout = null;

      if (options.enqueue) {
        self.enqueue($el);
      }

      if (options.auto) {
        $(self.options.currentContext).scroll(bind(self._scroll, self));
        self.load();
      }
    },

    // Adds elements to the queue of elements to load and re-sort.
    enqueue(el) {
      var self = this;
      var $el = $(el);
      var $queue = $();

      $el.find(self.options.responsiveImages).swapresponsiveimage();

      if ($el.data('src') || $el.data('background-image') || $el.data('srcset')) {
        $queue = $queue.add($el);
      }

      $queue = $queue.add($el.find('[data-src], [data-background-image], [data-srcset]'));

      self._$queue = self._$queue.add($queue);
      self._recalc();
      self._sort();

      return self.element;
    },

    forceRecalc() {
      this._recalc();
      this._scroll();
    },

    // Loads all the images in the queue, optionally throttling the amount of concurrent loads.
    load() {
      var self = this;
      var options = self.options;

      // Clone `._$queue` so we can splice elements without affecting the iteration.
      $(self._$queue.get()).each(function(i, el) {
        var $el = $(el);
        var $context = $(self.options.currentContext);
        var distance = $el.data('distanceFromWindowCenter') - $el.height() / 2;

        if (has('mobile')) {
          distance = $el.offset().top - $context.scrollTop();
        }

        if (options.proximity === false || distance < ($context.height() + options.proximity)) {
          var url;
          var data = $el.data();

          // Remove the element from the queue.
          self._$queue = self._$queue.not(el);

          if (data.src) {
            url = data.src;
          } else if (data.backgroundImage) {
            url = data.backgroundImage;
          } else if (data.srcset) {
            var image = data.srcset;

            $el.attr('srcset', image)
              .removeAttr('data-srcset')
              .removeData('srcset');

            return; // $.preload does not need to handle srcset values
          }

          if (self._getDevicePixelRatio() == 2 && data.lazyloadHighres) {
            var splittedUrl = url.split(/\.(gif|jpg|png|svg)/, 2);

            url = splittedUrl[0] + options.highResSuffix + '.' + splittedUrl[1];
          }

          if (options.ajaxloader && !$el.is('source')) {
            $el
              .ajaxloader($.isPlainObject(options.ajaxloader) ? options.ajaxloader : {})
              .ajaxloader('show');
          }

          self._loading++;

          // Since we're using `preload` with the "src" mode the image element won't be included in the data
          // so we need to curry the callbacks in order to inject the image element into the data.
          $.preload([url], {
            onComplete(data) {
              self._complete($.extend(data, { original: el }));
            },
            onRequest(data) {
              self._request($.extend(data, { original: el }));
            },
          });

          if (options.throttle && self._loading === options.throttle) {
            return false;
          }
        } else {
          // Since the queue is sorted by distance from window center,
          // as soon as we've gone past the threshold we can abort.
          return false;
        }
      });

      if (!self._loading) {
        self._trigger('finish');
        $.publish('lazyLoaderComplete');
      }

      return self.element;
    },

    // Get the current queue as an array.
    queue() {
      return this._$queue.get();
    },

    // Clean up ajax loaders.
    _complete(data) {
      var self = this;
      var image = data.image;
      var $el = $(data.original);
      var widget = $el.data('bc-ajaxloader');

      self._loading--;

      if (widget) {
        widget.destroy();
      }
      if (data.found) {
        if ($el.data('src') || $el.attr('src')) { // Make sure a second run doesn't add adittional attrs
          $el.attr('src', image)
            .removeAttr('data-src')
            .removeData('src');
        } else {
          $el.css('background-image', 'url(' + image + ')')
            .removeAttr('data-background-image')
            .removeData('background-image');
        }
        self._trigger('complete', null, data);
      } else {
        self._trigger('error', null, data);
      }

      // Recalc in-case the loading of images without predefined height/width attributes
      // pushed content down the screen.
      self._recalc();
      self.load();
    },

    // Cleanup timeouts and preloading images.
    _destroy() {
      var self = this;

      clearTimeout(self._timeout);
      self._$images.off('load error abort');
    },

    // TODO mcambronero: move this into utils
    _getDevicePixelRatio() {
      return window.devicePixelRatio;
    },

    // Determines the center-point of every element in the queue.
    // This is an "expensive" operation (calculating offsets) so done less frequently than the sorting.
    _recalc() {
      var self = this;

      self._$queue.each(function() {
        var $el = $(this);
        var centerPoint = $el.offset().top + ($el.height() / 2);

        $el.data('centerPoint', centerPoint);
      });
    },

    // Triggered by `preload` used to remember the spawned image preloaders for cleanup.
    _request(data) {
      var self = this;

      self._$images = self._$images.add(data.element);
      self._trigger('request', null, data);
    },

    // Triggered when the user scrolls the window with a slight delay to not run when scrolling is full bore.
    _scroll() {
      var self = this;

      clearTimeout(self._timeout);

      self._timeout = setTimeout(function() {
        self._sort();
        self.load();
      }, 20);
    },

    // Sort the queue by what's closest to the middle of the viewport based on the current window scroll pos.
    _sort() {
      var self = this;
      var $win = $(self.options.currentContext);
      var windowScrollTop = $win.scrollTop();
      var windowCenterPoint = ($win.height() / 2);

      self._$queue.each(function() {
        var $el = $(this);
        var distanceFromWindowCenter = Math.abs($el.data('centerPoint') - windowScrollTop - windowCenterPoint);

        $el.data('distanceFromWindowCenter', distanceFromWindowCenter);
      });

      self._$queue = $(self._$queue.get().sort(function(a, b) {
        return $(a).data('distanceFromWindowCenter') - $(b).data('distanceFromWindowCenter');
      }));
    },
  });
});
