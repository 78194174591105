// SwapResponsiveImage
// ===================
// Swap images when the breakpoints change, this is only is for background images
// if you need it for img or picture use srcset instead

define(function(require) {
  require('jquery-ui/widget');
  var stringUtils = require('common/util/string-utils');
  var { each } = require('underscore');

  $.widget('bc.swapresponsiveimage', {
    options: {
      background: {
        lazyLoadAttrImage: 'data-background-image',
        lazyLoadDataImage: 'backgroundImage',
      },
      isLazyLoad: true,
      sizes: [
        'base',
        'small',
        'medium',
        'large',
        'x-large',
      ],
    },

    _create() {
      var self = this;

      self.breakpointImageUrls = {};
      self.initLazyLoadProps = false;

      // Calculate the image urls for each breakpoint
      each(self.options.sizes, function(size, index) {
        var previousImage = self.options.sizes[index - 1];
        var breakpointImage = self.element.data(self.options.background.lazyLoadDataImage + stringUtils.capitalize(size));

        self.breakpointImageUrls[size] = breakpointImage;

        $.subscribe('breakpoint.' + size + '.match', self.setResponsiveImage.bind(self, size), self);

        if (previousImage) {
          $.subscribe('breakpoint.' + size + '.unmatch', self.setResponsiveImage.bind(self, previousImage), self);
        }
      });
    },

    _destroy() {
      var self = this;

      each(self.options.sizes, function(size) {
        $.unsubscribe('breakpoint.' + size + '.match', self.setResponsiveImage, self);
        $.unsubscribe('breakpoint.' + size + '.unmatch', self.setResponsiveImage, self);
      });
    },

    setResponsiveImage(size) {
      if (this.options.isLazyLoad && (!this.initLazyLoadProps || this._isImageNotLoaded())) {
        this.initLazyLoadProps = true;
        this.element.data(this.options.background.lazyLoadDataImage, this.breakpointImageUrls[size]);
        this.element.attr(this.options.background.lazyLoadAttrImage, this.breakpointImageUrls[size]);
      } else if (this.breakpointImageUrls[size]) {
        this.element.css('background-image', 'url(' + this.breakpointImageUrls[size] + ')');
      } else {
        this.element.css('background-image', 'none');
      }
    },

    _isImageNotLoaded() {
      return !!this.element.data[this.options.background.lazyLoadDataImage] || !!this.element.attr(this.options.background.lazyLoadAttrImage);
    },
  });
});
