define([
  'common/util/constructor-defaults',
  'underscore',
  'backbone.localstorage',
], function(constructorDefaults, { extend, bind, rest }) {
  var BaseController = Backbone.Router.extend({

    // CSS selectors for objects to be initialized on DOMReady
    _selectors: {},

    // Container for elements. Autogenerated from _selectors. See _initializeElements
    _elements: {},

    // Overrride the existing constructor to fire the `.ready()` method on DOMReady.
    // Also will extend `routes`, `_selectors`, and `options` objects using `_.extend()` in subclasses (rather than replace);
    constructor(options) {
      var self = this;

      options || (options = {});
      if (options.routes) {
        self.routes = extend({}, self.routes, options.routes);
        delete options.routes;
      }
      if (options._selectors) {
        self._selectors = extend({}, self._selectors, options._selectors);
        delete options._selectors;
      }
      constructorDefaults(self, '_selectors');
      constructorDefaults(self, 'routes');
      self.options = extend({}, self.options, options);
      constructorDefaults(self, 'options');
      Backbone.Router.call(self, options);

      // Bind to document ready after we're sure `.initialize()` has already been called.
      $(bind(self._ready, self));
      // Bind to after page load. Window load event won't always trigger
      if (window.addEventListener) {
        window.addEventListener('load', $.proxy(self._loaded, self), false);
      } else if (window.attachEvent) {
        window.attachEvent('onload', $.proxy(self._loaded, self));
      }

      // Helper functions for the new breakpoints
      $.subscribe('breakpoint.small.match', function() {
        self._smallBreakpoint;
      });
      $.subscribe('breakpoint.medium.match', function() {
        self._mediumBreakpoint;
      });
      $.subscribe('breakpoint.large.match', function() {
        self._largeBreakpoint;
      });
    },

    // Things that require the DOM to be ready should be setup here.
    ready() {
    },

    // This can be used for things below the fold, hidden or deferred
    loaded() {
    },

    // Overwrite this in the specific controllers to map behaviour to diferent breakpoints
    smallBreakpoint() {
    },

    mediumBreakpoint() {
    },

    largeBreakpoint() {
    },

    // Initialize elements based on selectors provided in the _selectors object
    _initializeElements() {
      var elements = this._elements;

      $.each(this._selectors, function(key, selector) {
        elements[key] = $(selector);
        elements[key].length || $.warn('selector "' + key + '" matched no elements.');
      });
    },

    // Don't override this, override ready() in subclasses
    _ready() {
      // Init DOM elements
      this._initializeElements();
      this.ready();
    },

    _loaded() {
      this.loaded();
    },

    // Don't override this, override smallBreakpoint() in subclasses
    _smallBreakpoint() {
      this.smallBreakpoint();
    },

    _mediumBreakpoint() {
      this.mediumBreakpoint();
    },

    _largeBreakpoint() {
      this.largeBreakpoint();
    },

    // Wrapper to make calling parent class super methods easier
    // TODO use jQuery UI syntax
    _super(parentClass, funcName) {
      return parentClass.prototype[funcName].apply(this, rest(arguments, 2));
    },
  });

  return BaseController;
});
