// AjaxLoader
// ===================
// Show an ajax loader relative to an element.
define(function(require) {
  require('jquery-ui/widget');
  var { isObject } = require('underscore');

  $.widget('bc.ajaxloader', {
    options: {
      busyClass: 'ui-ajaxloader-busy',
      className: 'ui-ajaxloader',
      fps: 20,
      frames: 12,

      // Can be `appendTo`, `prependTo`, `insertBefore` or `insertAfter`.
      inject: 'insertAfter',

      // Accepts two values as a string separated by a space (similar to background-position)
      // that may be any combination of `center`, `left`, `top`, `right` or `bottom`
      // to absolutely position the ajax loader element, or `static` for no positioning.
      position: 'static',

      // Can be `small` or `large`.
      size: 'small',

      // Can be `light` or `dark`.
      style: 'light',

      // Object of custom styles
      customCss: null,
    },

    // Creates the ajax loader element.
    _create() {
      var self = this;
      var options = self.options;
      var className = options.className;

      self.$ajaxLoader = $('<div />')
        .addClass(className)
        .addClass(className + '-' + options.size)
        .addClass(className + '-' + options.style);

      self._frame = 0;
    },

    // Removes the ajax loader element from the DOM.
    hide() {
      var self = this;

      self.$ajaxLoader.remove();
      self.element.removeClass(self.options.busyClass);
      clearTimeout(self._timeout);
    },

    // Inserts the ajax loader into the DOM relative to the widget element and sets the animation interval.
    show() {
      var self = this;
      var options = self.options;

      self.$ajaxLoader[options.inject](self.element);
      self.element.addClass(options.busyClass);
      self._positioned || self._position();
      self._styles();

      clearTimeout(self._timeout);
      self._timeout = setInterval($.proxy(self, '_animate'), 1000 / options.fps);
    },

    // Animates the ajax loader background sprite.
    _animate() {
      var self = this;
      var options = self.options;
      var frame = self._frame = (self._frame + 1) % options.frames;
      var $ajaxLoader = self.$ajaxLoader;
      var height = $ajaxLoader.height();
      var position = $ajaxLoader.css('background-position');

      $ajaxLoader.css('background-position', position.replace(/\d+(px)?$/, (height * frame) + 'px'));
    },

    // Clean up.
    _destroy() {
      this.hide();
    },

    // TODO no need to re-invent jquery.ui.position – refactor to use that.
    //
    // Absolutely positions the ajax loader element using the `position` property from the options object.
    // Accepts two values as a string separated by a space (similar to background-position)
    // that may be any combination of `center`, `left`, `top`, `right` or `bottom`
    // to absolutely position the ajax loader element, or `static` for no positioning. For example
    // 'center top' will position the loader so that it is centered horizontally,
    // but aligned vertically to the top of the container.
    // 'left center' will position the loader so that it is centered vertically,
    // but aligned horizontally to the left of the container.
    // 'center' will position the loader so that it is centered horizontally and vertically.
    _position() {
      var self = this;
      var position = self.options.position;
      var $ajaxLoader = self.$ajaxLoader;
      var height = $ajaxLoader.height();
      var width = $ajaxLoader.width();
      var css = {};
      var x;
      var y;

      if (position !== 'static') {
        css.position = 'absolute';
        position = position.split(' ');
        x = position[0];
        y = position[1] || x;

        if (x === 'center') {
          css.left = '50%';
          css['margin-left'] = -(width / 2);
        } else {
          css[x] = 0;
        }

        if (y === 'center') {
          css.top = '50%';
          css['margin-top'] = -(height / 2);
        } else {
          css[y] = 0;
        }
      }

      $ajaxLoader.css(css);
      self._positioned = true;
    },

    _styles() {
      if (isObject(this.options.customCss)) {
        this.$ajaxLoader.css(this.options.customCss);
      }
    },
  });
});
